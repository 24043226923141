<template>
  <div class="container">
    <h1>小桃子想吃雪糕么</h1>
    <img src="../assets/img/iceCream.jpg" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 5,
    };
  },
  methods: {
    load() {
      // setTimeout(() => {
      //    this.count += 2;
      // }, 1000);
    },
  },
};
</script>

<style>
.container {
}
</style>
